<template>
  <div class="wraper pagezclist">
    <div id="printDiv" v-if="showPrinter"></div>

    <SXListTable ref="SXListTable"></SXListTable>

    <!--新增-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible_add" width="1000px" custom-class="cus_dialog"
               :close-on-click-modal="false">
      <el-form :model="EditItem" ref="addzc" label-width="120px">
        <div class="titleBar flexStart">
          <div class="div flexStart">耗材信息</div>
        </div>
        <el-row class="flexStart" style="margin-top: 10px;">
          <el-col :span="12">
            <el-form-item label="*耗材名称:" prop="title">
              <el-autocomplete
                  class="inline-input" size="mini" :disabled="disabled"
                  v-model="EditItem.title"
                  :fetch-suggestions="querySearchTitle"
                  @select="handleSelectTitle"
                  placeholder="请输入内容"
                  style="width: 100%;"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button icon="el-icon-search" type="primary" size="mini" @click="onCheckSearch"
                       style="margin-bottom: 20px;">检索
            </el-button>

          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="false" :span="10">
            <el-form-item label="*耗材分类:" prop="zc_typeid">
              <el-cascader ref="myCascader1" v-model="EditItem.zc_typeid" :options="typeList" size="mini"
                           :disabled="disabled"
                           :props="optionProps1" :show-all-levels="true" filterable clearable placeholder="请选择"
                           @change="handleCascaderChange1"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col v-if="false" :span="2">
            <div style="text-align: center;  padding-top: 8px;">
              <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="dialogVisible_sxType=true">新增分类
              </el-button>

              <!--              <el-link href="#/hctype" type="primary">新增分类</el-link>-->
            </div>

          </el-col>

          <el-col :span="12">
            <el-form-item label="*品牌:" prop="pinpai">
              <el-autocomplete
                  class="inline-input" size="mini" :disabled="disabled"
                  v-model="EditItem.pinpai"
                  :fetch-suggestions="querySearchPinpai"
                  placeholder="请选择或输入"
                  style="width: 100%;"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col v-if="false" :span="3">
            <el-popover
                placement="top" width="260"
                v-model="popover_pinpaiAdd" trigger="click">
              <div class="">
                <el-input v-model="pinpaiVal" size="mini" placeholder="请填写品牌"></el-input>
              </div>
              <div style="text-align: right; margin-top: 10px;">
                <el-button type="primary" plain size="mini" @click="popover_pinpaiAdd = false">取消</el-button>
                <el-button type="primary" size="mini" @click="addpinpai">确定</el-button>
              </div>

              <div slot="reference" style="text-align: center;  padding-top: 8px;">
                <el-button size="mini" type="primary" plain icon="el-icon-plus">新增品牌</el-button>
              </div>
            </el-popover>

          </el-col>

          <el-col :span="12">
            <el-form-item label="*型号:" prop="xinghao">
              <el-autocomplete
                  class="inline-input" size="mini" :disabled="disabled"
                  v-model="EditItem.xinghao"
                  :fetch-suggestions="querySearchXinghao"
                  placeholder="请选择或输入"
                  style="width: 100%;"
              ></el-autocomplete>
              <!--              <el-input v-model="EditItem.xinghao" size="mini" :disabled="disabled" placeholder="请输入"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="*规格:" prop="guige">
              <el-autocomplete
                  class="inline-input" size="mini" :disabled="disabled"
                  v-model="EditItem.guige"
                  :fetch-suggestions="querySearchGuige"
                  placeholder="请选择或输入"
                  style="width: 100%;"
              ></el-autocomplete>
              <!--              <el-input v-model="EditItem.xinghao" size="mini" :disabled="disabled" placeholder="请输入"></el-input>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="false" :span="12">
            <el-form-item label="计量单位:" prop="jldw">
              <el-input v-model="EditItem.jldw" size="mini" disabled placeholder="请选择分类"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="*所在位置:" prop="zc_storeid">
              <el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
                           :disabled="disabled"
                           :props="optionProps" :show-all-levels="true" filterable clearable placeholder="请选择"
                           @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="false" :span="12">
            <el-form-item label="购置方式:" prop="zc_gztype">
              <el-select v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="请选择">
                <el-option v-for="(item,index) in purchaseType" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="false" :span="12">
            <el-form-item label="购置日期:" prop="zc_date">
              <el-date-picker v-model="EditItem.zc_date" type="date" size="mini" :disabled="disabled"
                              value-format="yyyy-MM-dd"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="12" v-for="(zd,idx) in EditItem.zdjson" :key="idx">
            <el-form-item :label="zd.name+':'">
              <el-input v-if="!zd.type||zd.type=='string'" size="small" v-model="zd.value" :disabled="disabled"
                        placeholder="请输入"></el-input>
              <el-input type="number" v-if="zd.type=='number'" v-model="zd.value" size="small" :disabled="disabled"
                               placeholder="请输入"  @mousewheel.native.prevent></el-input>
              <el-date-picker :editable='false' v-if="zd.type=='date'" v-model="zd.value" style="width: 100%" type="date" size="small"
                              :disabled="disabled"
                              value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="*单价:" prop="jine">
              <el-input v-model="EditItem.jine" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="*数量:">
              <el-input-number v-model="EditItem.store_num" size="mini" :min="0" :disabled="disabled||dialogTitle=='编辑'"
                               placeholder="请输入"></el-input-number>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="8">-->
          <!--            <el-form-item label="总价金额:">-->
          <!--              <el-input size="mini" disabled placeholder="请先输入单价和数量">{{parseInt(EditItem.jine)*parseInt(EditItem.store_num).toFixed(2)}}</el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="8">
            <el-form-item label="预警数量:">
              <el-input-number v-model="EditItem.yujing_num" size="mini" :disabled="disabled" controls-position="right"
                               placeholder="请输入"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="使用人:">
              <el-input size="small" v-model="EditItem.bgr" :disabled="disabled"
                        placeholder="请输入使用人/保管人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验收日期:">
              <el-date-picker v-model="EditItem.delivery_date" type="date" size="mini" :disabled="disabled"
                              value-format="yyyy-MM-dd"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验收人:">
              <el-input size="small" v-model="EditItem.checker" :disabled="disabled"
                        placeholder="请输入验收人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="时长超一年:">
              <el-select v-model="EditItem.is_over_year" size="mini" clearable :disabled="disabled" placeholder="请选择">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述说明:" prop="zcdesc">
              <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zcdesc" size="mini"
                        :disabled="disabled"
                        placeholder="描述说明"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="false" :span="24">
            <el-form-item label="上传图片:" v-show="!disabled">
              <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :on-preview="uploadPreview"
                         :on-remove="handleRemove" :file-list="files" name="image">
                <el-button size="mini" plain icon="el-icon-upload2" type="primary">点击上传
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="上传图片:" v-show="disabled&&files&&files.length>0">
              <!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
              <div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
                <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
              </div>
            </el-form-item>
          </el-col>

        </el-row>

        <el-divider content-position="left">
          <el-button type="text" size="small" @click="addCusField">字段不够?点此添加</el-button>
        </el-divider>
        <div class="titleBar flexStart">
          <div class="div flexStart">供应商信息</div>
        </div>
        <el-row style="margin-top: 10px;">
          <el-col :span="12">
            <el-form-item label="供应商:" prop="wb_gxy">
              <el-input v-model="EditItem.wb_gxy" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人:" prop="wb_lxr">
              <el-input v-model="EditItem.wb_lxr" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话:" prop="wb_phone">
              <el-input v-model="EditItem.wb_phone" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保修结束日期:" prop="wb_deadline">
              <el-date-picker v-model="EditItem.wb_deadline" type="date" size="mini" :disabled="disabled"
                              value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注:" prop="wb_desc">
              <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.wb_desc" size=""
                        :disabled="disabled"
                        placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button v-if="!disabled" type="primary" @click="saveForm()">保存</el-button>

        <el-button v-if="disabled" type="primary" @click="dialogVisible_add=false">关闭</el-button>
      </div>
    </el-dialog>

    <!--添加分类-->
    <el-dialog title="实训耗材分类" :visible.sync="dialogVisible_sxType" width="600px" custom-class="width_800 cus_dialog">

      <sxType name="实训耗材分类" type="sx" parentPage="sxlist"></sxType>

    </el-dialog>

    <!--       新增字段-->
    <el-dialog title="自定义字段" :visible.sync="showCusField" width="600px" custom-class="width_800 cus_dialog">


      <el-form :model="FieldItem" ref="FieldItem" label-width="120px">
        <el-row style="margin-top: 20px;">
          <el-form-item label="所属类型:">
            <el-input v-model="FieldItem.value" disabled></el-input>
          </el-form-item>

          <el-form-item label="设备名称:">
            <el-input v-model="FieldItem.cname" disabled></el-input>
          </el-form-item>
          <el-form-item label="英文编码:">
            <el-input v-model="FieldItem.ename" disabled></el-input>
          </el-form-item>
          <el-divider style="width: 100%"></el-divider>
          <div style="color: #409EFF;margin: 10px 0 10px 0;">自定义字段</div>
          <el-row v-for="(c,idx) in FieldItem.zdjson" class="flexStart drawer_box">
            <el-col :span="11">
              <el-form-item label="字段名称:" label-width="80px">
                <el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" style="margin-left: 10px;">
              <el-form-item label="字段类型" label-width="80px">
                <el-select size="small" v-model="c.type" placeholder="请选择">
                  <el-option value="string" label="文本"></el-option>
                  <el-option value="number" label="数值"></el-option>
                  <el-option value="date" label="日期"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i
                class="el-icon-remove"></i></el-tag>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus"
                       circle></el-button>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveCusField">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="增加库存" :visible.sync="dialogVisible_store" width="600px" custom-class="width_800 cus_dialog">


      <el-form :model="EditItem" :rules="rules_store" ref="addstore" label-width="90px"
               @submit.native.prevent>
        <el-row style="margin-top: 20px;">
          <el-form-item label="入库数量:" prop="store_num">
            <el-input-number v-model="EditItem.store_num" placeholder="请输入"></el-input-number>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.remark"
                        :disabled="disabled"
                        placeholder="库存修改说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveStore('addstore')">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>

<!--库存日志-->
    <StoreLogList  v-if="dialogVisible_logList" :dialogVisible_logList="dialogVisible_logList" :dialogTitle="dialogTitle" ref="StoreLogList" :EditItem="EditItem"></StoreLogList>

    <!--库存日志-->
    <YujingList  v-if="dialogVisible_yujing" :dialogVisible_yujing="dialogVisible_yujing" :dialogTitle="dialogTitle" ref="YujingList"></YujingList>

  </div>
</template>

<script>
export default {
  components: {
    SXListTable: () => {
      return import('./com_sxlist/SXListTable')
    },
    sxType: () => {
      return import("../components/Type.vue")
    },
    StoreLogList: () => {
      return import("./com_sxlist/StoreLogList.vue")
    },
    YujingList: () => {
      return import("./com_sxlist/YujingList.vue")
    },
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      searchForm: {},
      DataList: [],


      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible_add: false,
      dialogVisible: false,
      dialogVisible_sxType: false,
      dialogVisible_logList:false,
      EditItem: {},
      disabled: false,
      typeList: [],
      areaList: [],
      optionProps: {value: 'id', label: 'storename', emitPath: false,},
      optionProps1: {value: 'id', label: 'cname', emitPath: false,},
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogVisible_zcList: false,
      dialogVisible_store: false,
      storeNum: null,
      rules_store: {
        num: [
          {validator: checkNum, trigger: 'blur'}
        ],
      },
      purchaseType: [],
      pinpaiList: [],
      xinghaoList: [],
      popover_pinpaiAdd: false,
      pinpaiVal: '',
      save_type: 0,
      checkList: [],
      timeout: null,
      dialogVisible_yujing:false,
    }
  },
  mounted() {

    this.getCurDate();

    let _this = this
    if (localStorage.getItem("user")) {
      _this.userinfo = JSON.parse(localStorage.getItem("user"))
      // console.log(_this.userinfo)
    }
  },
  methods: {
    doIt() {
      this.getList();
      this.getType();
      this.getArea();

      this.getPurchaseType()
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", {key_type: "purchaseType"}).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    getPinPaiList() {
      this.$http.post("/api/sx_hc_pinpai", {zc_typeid: this.EditItem.zc_typeid}).then(res => {
        if (res.data && res.data.length > 0) {
          this.pinpaiList = res.data.map(a => {
            let obj = {value: a}
            return obj
          })
        }
      })
    },
    getXinghaoList() {
      this.$http.post("/api/sx_hc_xinghao", {zc_typeid: this.EditItem.zc_typeid}).then(res => {
        if (res.data && res.data.length > 0) {
          this.xinghaoList = res.data.map(a => {
            let obj = {value: a}
            return obj
          })
        }
      })
    },
    addpinpai() {
      this.pinpaiList.push(this.pinpaiVal)
      this.EditItem.pinpai = this.pinpaiVal
      this.popover_pinpaiAdd = false
    },
    getList(type) {
      let _this = this
      let url = "/api/sx_hc_list"
      if(type){
        url = "/api/sx_hc_xx_list"
      }
      return new Promise(resolve => {
        let params = {}, arr = []
        params.checkTitle = _this.EditItem.title ? _this.EditItem.title : ''
        params.zc_typeid = _this.EditItem.zc_typeid ? _this.EditItem.zc_typeid : ''
        params.pinpai = _this.EditItem.pinpai ? _this.EditItem.pinpai : ''
        params.xinghao = _this.EditItem.xinghao ? _this.EditItem.xinghao : ''
        params.page = 1
        params.pagesize = 10000000000

        this.$http.post(url, params).then(res => {
          if (res.data.data && res.data.data.length > 0) {
            this.checkList = res.data.data.map((r) => {
              r.value = ''
              r.value = `${r.title}（${r.typename1 ? r.typename1 : ''} ${r.pinpai ? r.pinpai : ''} ${r.xinghao ? r.xinghao : ''}）`
              return r
            });
            // console.log(this.checkList)
            resolve(this.checkList)
          } else {
            this.checkList = []
            resolve(this.checkList)

          }
        })
      })

    },
    querySearchTitle(queryString, cb) {
      var restaurants = this.checkList;
      var results = queryString && queryString != undefined ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString, name) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
      };
    },
    handleSelectTitle(item) {
      this.EditItem = JSON.parse(JSON.stringify(item))
      this.getPinPaiList()
      this.getXinghaoList()
    },

    querySearchPinpai(queryString, cb) {
      var restaurants = this.pinpaiList;
      var results = queryString && queryString != undefined ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchXinghao(queryString, cb) {
      var restaurants = this.xinghaoList;
      var results = queryString && queryString != undefined ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchGuige(queryString, cb) {
      var restaurants = this.checkList.map(c => {
        return {value: c.guige || ""}
      })
      var results = queryString && queryString != undefined ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    // 新增
    add() {
      this.save_type = 0
      this.pinpaiList = []
      let _this = this
      this.dialogTitle = '新增耗材'
      this.dialogVisible_add = true
      this.disabled = false
      this.EditItem = {
        zc_date: _this.curDate,
      }
      this.FieldItem = {}
      this.files = []
      this.files = [..._this.files]
      this.doIt()
    },
    editItem(row, flag) {
      // console.log(row)
      this.doIt()
      this.save_type = 1
      let _this = this
      this.dialogTitle = '编辑'
      this.dialogVisible_add = true
      this.disabled = false


      let obj = JSON.parse(JSON.stringify(row))
      let arr = []
      if (obj.files != '' && obj.files != null && obj.files != undefined) {
        arr = JSON.parse(obj.files)
        _this.files = arr
      }
      if (obj.remark && obj.remark != '') {
        obj.zdjson = JSON.parse(obj.remark)
      }
      if(!obj.wb_deadline||obj.wb_deadline == 'Invalid date'||obj.wb_deadline=='null'||obj.wb_deadline=='undefined'){
        obj.wb_deadline = ''
      }
      if (flag) {
        for (let key in obj) {
          if (!obj[key]) {
            obj[key] = '-'
          }
        }
      }
      _this.EditItem = {...obj}
      if (_this.EditItem.zc_typeid) {
        this.getPinPaiList()
        this.getXinghaoList()
        this.getTypeDetail(_this.EditItem.zc_typeid)
      }
    },
    onSearchAdd(isSaveForm) {
      return new Promise(resolve => {
        if (!this.EditItem.title) {
          this.$message.warning('请填写耗材名称！')
          return
        }
        if (!this.EditItem.zc_typeid) {
          this.$message.warning('请选择耗材类型！')
          return
        }
        if (!this.EditItem.pinpai) {
          this.$message.warning('请选择品牌！')
          return
        }
        if (!this.EditItem.xinghao) {
          this.$message.warning('请填写型号！')
          return
        }
        this.getList(1).then(r => {
          resolve(this.checkList)
        })

      })

    },
    onCheckSearch() {
      this.onSearchAdd().then(checkList => {
        console.log(this.dialogTitle,checkList.length,checkList, this.EditItem.id)
        if (this.dialogTitle.indexOf('新增') > -1 && (checkList && checkList.length > 0)) {
          this.$message.warning(`${checkList[0].value}已存在，请勿重复添加~`)
        } else if ((this.dialogTitle.indexOf('编辑') > -1) && (checkList && checkList.length > 0) && (checkList[0].id != this.EditItem.id)) {
          this.$message.warning(`${checkList[0].value}已存在，请勿重复添加~`)
        } else {
          this.$message.success('检索成功，可继续填写~')
        }
      })
    },
    saveForm() {
      let _this = this
      this.onSearchAdd(1).then(checkList => {
        if (this.dialogTitle.indexOf('新增') > -1 && (checkList && checkList.length > 0)) {
          this.$message.warning(`${checkList[0].value}已存在，请勿重复添加~`)
          return;
        } else if ((this.dialogTitle.indexOf('编辑') > -1) && (checkList && checkList.length > 0) && (checkList[0].id != this.EditItem.id)) {
          this.$message.warning(`${checkList[0].value}已存在，请勿重复添加~`)
          return;
        } else {
          // this.$message.success('检索成功，可继续填写~')
          let params = {}
          params = {..._this.EditItem}
          if (!params.title) {
            _this.$message.warning('请填写耗材名称！')
            return
          }
          if (!params.zc_typeid) {
            _this.$message.warning('请选择耗材类型！')
            return
          }
          if (!params.zc_storeid) {
            _this.$message.warning('请选择所在仓库！')
            return
          }

          if (this.dialogTitle.indexOf('新增') > -1 && !params.jine) {
            _this.$message.warning('请填写耗材金额！')
            return
          }
          if (this.dialogTitle.indexOf('新增') > -1 && !params.store_num) {
            _this.$message.warning('请填写耗材数量！')
            return
          }
          if (params.wb_phone && !this.checkPhone(params.wb_phone)) {
            _this.$message.warning('供应商联系电话格式不正确~')
            return
          }
          if (this.files.length > 0) {
            params.files = JSON.stringify(_this.files)
          }
          params.remark = JSON.stringify(params.zdjson)
          params.save_type = this.save_type
          if (this.dialogTitle.indexOf('新增') > -1) {
            delete params.id
          }
          this.$http.post('/api/sx_hc_save', params).then(res => {
            console.log(668,_this.dialogVisible_add)
            if(res.data.code == 200||res.data.affectedRows){
              _this.dialogVisible_add = false;
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              _this.$refs.SXListTable.getList()
            }else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }
      })
    },
    checkPhone(value) {
      let isPhone = /^1[3-9]\d{9}$/;
      let isMob = /^((\+?86)|(\+86))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
      if (isMob.test(value) || isPhone.test(value)) {
        return true;
      } else {
        return false;
      }
    },
    uploadAttch(e) {
      let _this = this
      this.$http.post("/api/sx_hc_import2", {url: e.src}).then(res => {
        if(res.data.code==200){
          _this.$refs.SXListTable.getList()
          this.$message.success('导入成功');
        }else {
          this.$message.error(res.data.msg);
        }
        if (res.data && res.data.errordata && res.data.errordata.length > 0) {
          this.ErrLog = res.data.errordata
          this.dialogErrorVisible = true;
        }


      })
    },
    removeItem(item) {
      let _this = this
      if (item.store_num > 0) {
        this.$message.error('库存数量大于0无法删除，请清空库存再删除~')
      } else {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/api/sx_hc_delete", {
            id: item.id
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功'
            });

            _this.$refs.SXListTable.getList()
          })
        }).catch(() => {

        });
      }


    },
    checkstore_num(rows) {
      let _this = this
      let flag = 0
      return new Promise(resolve => {
        rows.map(a => {
          if (a.store_num > 0) {
            setTimeout(function () {
              _this.$message.error(`${a.title}剩余库存大于0无法删除，请清空库存再删除~`)
            }, 200)
            flag = 1
          }
        })
        resolve(flag)
      })
    },
    delSlct(rows) {
      let _this = this

      if (rows.length == 0) {
        _this.$message.warning('请选择至少一条数据')
      } else {
        this.checkstore_num(rows).then(flag => {
          console.log(712, flag)
          if (!flag) {
            _this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              _this.$http.post("/api/sx_hc_delete", {
                id: rows.map(item => {
                  return item.id
                }).join(',')
              }).then(res => {
                _this.$message.success('删除成功');
                _this.$refs.SXListTable.getList()
              })
            }).catch(() => {

            });
          }

        })

      }
    },
    showLog(row){
      this.EditItem = row
      this.dialogTitle = '【'+row.title+'】库存变动日志'
      this.dialogVisible_logList=true
    },
    closeLog(){
      this.dialogVisible_logList=false
    },
    showYujingList(){
      this.dialogTitle = '耗材预警清单'
      this.dialogVisible_yujing = true
    },
    closeYujing(){
      this.dialogVisible_yujing=false
    },
    //增加库存
    showStore(row) {
      console.log(row.id)
      this.EditItem = {
        id: row.id,
      }
      this.dialogVisible_store = true
    },
    saveStore() {
      let _this = this
      let params = {}
      params = {..._this.EditItem}
      console.log('请填写入库数量', _this.EditItem.store_num)
      if (!params.store_num) {
        _this.$message.warning('请填写入库数量！')
        return
      }
      if (!params.remark) {
        _this.$message.warning('请填写库存修改说明~')
        return
      }
      _this.$http.post('/api/upd_sx_hc_store_num', params).then(res => {
        _this.dialogVisible_store = false;
        _this.$message.success('保存成功');
        _this.$refs.SXListTable.getList()
      })

    },

    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    uploadCover(e) {
      this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    getType() {
      let _this = this
      this.$http.post("/api/zc_type", {type: 'sx'}).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getTypeDetail(id) {
      let _this = this
      this.$http.post("/api/zc_type_find_one", {id: id}).then(res => {
        _this.FieldItem = res.data && res.data.length ? res.data[0] : {}
      })
    },
    refreshType() {
      console.log(907, 'refreshType')
      this.getType()
      this.dialogVisible_sxType = false
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },

    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleCascaderChange(e) {
      console.log(e)
    },
    handleCascaderChange1(e) {
      if (e) {
        let _this = this
        _this.EditItem.zc_typeid = e
        let a = _this.$refs['myCascader1'].getCheckedNodes()[0].data
        // console.log(1114, a)
        _this.FieldItem = a
        _this.EditItem.ename = a.ename
        _this.EditItem.jldw = a.unit
        if (a.zdjson && a.zdjson != '') {
          _this.EditItem.zdjson = JSON.parse(a.zdjson)
        }
        _this.EditItem = {..._this.EditItem}
        this.getPinPaiList()
        this.getXinghaoList()
      }
    },
    /**
     * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
     * id:     匹配的值
     * data: 匹配的数组数据
     * prop: 匹配的字段名
     * children: 子集label
     * cname: 输出数组需要的字段名
     */
    getFathersById(id, data, prop, children, cname) {
      var arrRes = [], arrText = []
      const rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i]
          if (node[prop] == nodeId) {
            arrRes.unshift(node[prop])
            arrText.unshift(node[cname])
            return true
          } else {
            if (node[children] && node[children].length) {
              if (rev(node[children], nodeId)) {
                arrRes.unshift(node[prop])
                arrText.unshift(node[cname])
                return true
              }
            }
          }
        }
        return false
      }
      rev(data, id)
      return arrText  //返回中文集合
    },

    addCusField() {
      let _this = this
      if (!this.FieldItem.id) {
        this.$message.warning('请先选择资产分类！')
        return;
      }
      this.showCusField = true
      console.log(1103, _this.FieldItem.zdjson)
      if (_this.FieldItem.zdjson && _this.FieldItem.zdjson != '' && _this.FieldItem.zdjson != 'null' && _this.FieldItem.zdjson != 'undefined') {
        _this.FieldItem.zdjson = JSON.parse(_this.FieldItem.zdjson)
      } else {
        _this.FieldItem.zdjson = []
      }
      _this.FieldItem = {..._this.FieldItem}
    },
    saveCusField() {
      let _this = this
      let objvalue = _this.EditItem.zdjson    //保存后回显防止旧值被覆盖
      let params = {}, obj = {}
      obj = JSON.parse(JSON.stringify(_this.FieldItem))
      params = {...obj}
      params.remark = ""
      params.zdjson = JSON.stringify(obj.zdjson)
      console.log(888, params)
      this.$http.post("/api/zc_type_save", params).then(res => {
        _this.showCusField = false
        _this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getType()
        _this.EditItem.zdjson = JSON.parse(params.zdjson)
        for (let a in objvalue) {
          for (let b in _this.EditItem.zdjson) {
            if (a == b && objvalue[a] != '') {
              _this.EditItem.zdjson[b] = objvalue[a]
            }
          }
        }
        _this.EditItem = {..._this.EditItem}
      })
    },
    addCus() {
      let _this = this
      this.FieldItem.zdjson.push({})
      this.FieldItem = {..._this.FieldItem}
    },
    delCus(item, index) {
      let _this = this
      this.FieldItem.zdjson.splice(index, 1)
      this.FieldItem = {..._this.FieldItem}
    },

    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },

  }
}
</script>

<style lang="less" type="text/scss">



.pagezclist {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input, .el-select, .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box, .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}


</style>
